<template>
    <div class="cursor flex-c"><img  style="margin-left:0.625rem" src="../../../assets/icons/xiazai.png"  /></div> 
</template>

<script>
export default {

}
</script>

<style>

</style>