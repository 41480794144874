<template>
  <div>
    <!-- 标题栏 -->
    <TitleCard>
      <template v-slot:title><Title>僵尸号台账</Title></template>
       <template v-slot:icon>
        <el-tooltip placement="top" content="超过3个月未发文的政务类台账" >
            <img style="width:1rem;height:1rem;margin-left:0.625rem" src="@/assets/img/icon/tishi.png" alt="" srcset="">
</el-tooltip>
    </template>
           <template v-slot:export>
        <Ecport v-loading="exportLoading" @click.native="exportHSOUYE(Index.exportThreeMonthsNoMessage)"/>
        </template>
            <template v-slot:com>
        <!-- <TitleNext :desc="'更多'" @click.native="goMonitoringList" /> -->
      </template>
    </TitleCard>
    <el-table :header-cell-style="{'text-align':'center'}"
    :cell-style="{'text-align':'center'}" :data="tableData" style="width: 100%" 
      v-loading="tabelLoading"
      height="520"
    >
      <el-table-column prop="platformTypeName" label="问题名称">  
        
        <template slot="header" slot-scope="scope">
          <el-dropdown @command="handleClickPingTai">
            <span class="cursor">
              {{platformName}}<i class="el-icon-arrow-down el-icon--right"></i>
            </span>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item
                v-for="(item, inx) in PTLXList"
                :key="inx"
                :command="item.value"
                >{{ item.label }}</el-dropdown-item
              >
            </el-dropdown-menu>
          </el-dropdown>
        </template>
            <template slot="default" slot-scope="scope">
              <div class="work-cell">
                      <img :src="require(`@/assets/img/xiaoPingTai/${scope.row.platformType}.png`)" width="16" height="16"> 
                                    <span style="margin-left:0.3125rem">{{scope.row.platformTypeName}}</span>
              </div>
        

            </template>
         </el-table-column>
      <el-table-column prop="accountName" label="账号名称"> </el-table-column>
      <el-table-column prop="latestDataTime" label="最后发文时间"> </el-table-column>
    </el-table>
  </div>
</template>

<script>
import TitleCard from "../../../components/TitleCard";
import Title from "../../../base/Title";
import TitleNext from "../../../base/TitleNext";
import Index from "@/api/index/index";
import mixins from './minxin.js'
import Ecport from './ecport'
export default {
    props:['PTLXList'],
    components:{TitleCard,Title,TitleNext,Ecport},
      mixins:[mixins()],
  data() {
    return {
      tableData: [],Index
    };
  },
  created() {
    this.getInfo();
  },
  methods: {
    async getInfo(data) {
        this.tabelLoading = true
      const Res = await Index.threeMonthsNoMessage({
                  "pageNo": "1",
                "pageSize": "10",
                ...data
      })
       this.tableData = Res.data.data.list;
         this.tabelLoading = false
    },
    goMonitoringList() {

    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .el-table td.el-table__cell {
  border-bottom: none !important;
}
.centered-table .cell{
     text-align: center;
  vertical-align: middle;
}
  .el-dropdown-menu {
    overflow: hidden;
  height: 420px !important;
  overflow:auto;
}
</style>
