// 监测方案
<template>
  <div>
    <!-- 标题栏 -->
    <TitleCard>
      <template v-slot:title><Title>监测方案</Title></template>
      <template v-slot:com>
        <TitleNext :desc="'全部方案'" @click.native="goMonitoringList" />
      </template>
    </TitleCard>
    <!-- 内容 -->
    <el-card shadow="never" v-loading="specialActionsLoading" class="box5-height">
      <div class="news-box">
        <template v-if="newsdata.length > 0">
          <div
          v-for="(item, index) in newsdata"
          :key="index"
          :news="item"
          class="newslist"
          @click="goDetail(item)"
        >
          <div class="newslist-left">{{ item.name }}</div>
          <div class="newslist-right m-l-16">
            <span class="num">{{ item.count }}</span>
            <span class="num2 m-l">
              <img src="../../../assets/img/icon/top.png" alt="" srcset="" />
              {{ item.newCount }}
            </span>
          </div>
        </div>
        </template>
      
        <div class="analyse-container" v-else>
    <div class="analyse-container-img">
      <img src="@/assets/img/gjh/nojc.png" style="width:12.5rem"/>
      <p>监测方案暂无数据</p>
    </div>
  </div>
      </div>
     
    </el-card>
  </div>
</template>

<script>
import TitleCard from "../../../components/TitleCard";
import Index from "@/api/index/index";
import NewsList from "../../../base/NewsList";
import TitleNext from "../../../base/TitleNext";
import Title from "../../../base/Title";
export default {
  data() {
    return {
      specialActionsLoading: false, //加载效果
      newsdata: [], // 信息列表里的数据
    };
  },
  components: {
    TitleCard,
    Title,
    TitleNext,
    NewsList,
  },
  methods: {
    // 专项行动首页列表
    async getPlanMonitorList() {
      this.specialActionsLoading = true;
      const res = await Index.getPlanMonitorList({
        limit: 10,
        page: 1,
      });
      if (res.data.code == 200) {
        this.newsdata = res.data.data;
        this.specialActionsLoading = false;
      }
    },
    // 进入详情页
    goDetail(item) {
      console.log(item,'tiao');
      this.$main.routeResolve("/monitor", {...item,isIndex:true,relationId:230});
      return
      this.$router.push({
        path: "/monitor",
      });
    },
    // 跳转检测方案
    goMonitoringList() {
      this.$router.push({
        path: "/monitor",
      });
    },
  },
  created() {
    this.getPlanMonitorList();
  },
};
</script>

<style lang="scss" scoped>
::v-deep .el-loading-spinner .path {
  stroke: #ed731f;
}
.news-box {
  height: 240px;
  box-sizing: border-box;
  overflow-y: auto;
     /* 暂无数据用的 */
     .analyse-container {
    width: 100%;
    height: 100%;
    background: #ffffff;
    border-radius: 4px;
    // margin-top: 60px;
    // text-align: center;
    padding-top: 60px;
    .analyse-container-img {
      text-align: center;
  }
  
  .analyse-container-img p {
    font-size: 14px;
    color: #999999;
  }
  }
  .newslist {
    padding: 15px 16px 15px 16px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: #333746;
    font-size: 14px;
    cursor: pointer;
    .newslist-left {
      flex: 1;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
    .newslist-right {
      .num2 {
        color: #02bc7c;
      }
    }
  }
  .newslist:hover {
    background: #f4f7fd;
  }
}
.box5-height{
  //  height: 240px;
}
</style>
