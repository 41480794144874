// 有害信息数量情况
<template>
  <div>
    <!-- 标题栏 -->
    <TitleCard>
      <template v-slot:title>
        <Title>有害信息数量情况</Title>
      </template>
      <template v-slot:com>
        <ButtonSelection :buttonList="buttonList" :defaultIndex="1" @setButtonSelectFn="setButtonSelectFn" />
      </template>
    </TitleCard>
    <!-- 内容 -->
    <el-card shadow="never" v-loading="specialActionsLoading" class="box7-height">
      <div>
        <div class="judgedDataTeamName">
          <el-tabs v-model="teamActiveName" @tab-click="judgedNavChange">
            <el-tab-pane v-for="(item, index) in tabLIst" :key="index" :label="item.label"
              :name="String(item.value)"></el-tab-pane>
          </el-tabs>
        </div>
        <div id="harmful" class="harmful-chart"></div>
      </div>

    </el-card>
  </div>
</template>

<script>
import TitleCard from "../../../components/TitleCard";
import ButtonSelection from "../../../components/ButtonSelection";
import Title from "../../../base/Title";
import Index from "@/api/index/index";
import Error from "@/api/error/index";
import { pxToResponsivePx } from "@/utils/pxToResponsivePx";
export default {
  components: {
    TitleCard,
    Title,
    ButtonSelection,
  },
  data() {
    return {
      specialActionsLoading: false, //加载效果
      harmX: [],
      harmY: [],
      buttonList: ["本月", "本年度"],
      teamActiveName: "1",
      tabLIst: [],
      cycleData: 0, //切换时间周期
    };
  },
  created() {
    localStorage.getItem("platform_type")
      ? (this.tabLIst = JSON.parse(localStorage.getItem("platform_type")))
      : this.getPlatformList();
    // this.getHarmfulInfNum();
  },
  mounted() { },
  methods: {
    // 获取有害信息数量情况
    async getHarmfulInfNum() {
      this.specialActionsLoading = true;
      this.harmX = [];
      this.harmY = [];
      const res = await Index.getHarmfulInfNum({
        period: this.cycleData,
        platformTypes: this.teamActiveName,
      });
      if (!res.data.data) {
        this.harmX = []
        this.harmY = []
        this.specialActionsLoading = false;
        this.setEcharts();
        return;
      }
      if (res.data.code == 200 && res.data.data.length >= 0) {
        res.data.data.forEach((x) => {
          // eslint-disable-next-line no-self-assign
          x.value == "" ? (x.value = "未知类型") : (x.value = x.value);
          this.harmX.push(x.value);
          this.harmY.push(x.num);
        });
        this.setEcharts();
        this.specialActionsLoading = false;
      }
    },
    // 平台切换
    judgedNavChange() {
      this.getHarmfulInfNum();
    },
    // 获取平台类型
    async getPlatformList() {
      const res = await Error.getPlatformList();
      if (res.data.code == 200) {
        this.tabLIst = res.data.data;
      }
    },
    // 时间周期切换
    setButtonSelectFn(inx) {
      inx == 1 ? (this.cycleData = 3) : (this.cycleData = inx);
      this.teamActiveName = "1";
      this.getHarmfulInfNum();
    },
    setEcharts() {
      if (!document.getElementById("harmful")) return
      const myChart = this.$echarts.init(document.getElementById("harmful"));
      // 指定图表的配置项和数据
      var option = {
        //你的代码
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "shadow",
          },
          textStyle: {
            fontSize: pxToResponsivePx(12),
          }
        },
        grid: {
          left: "3%",
          top: "10%",
          right: "3%",
          bottom: "0%",
          containLabel: true,
        },
        xAxis: [
          {
            type: "category",
            data: this.harmX,
            axisTick: {
              show: false,
            },
            axisLabel: {
              interval: 0,
              textStyle: {
                color: "#B8BCC3",
                fontSize: pxToResponsivePx(12),
              },
              formatter: function (value) {
                if (value.length > 4) {
                  return `${value.slice(0, 4)}...`;
                }
                return value;
              },
            },
            axisLine: {
              show: true,
              lineStyle: {
                color: "#D8D8D8",
              },
            },
          },
        ],
        yAxis: [
          {
            type: "value",
            axisTick: {
              show: false,
            },
            nameTextStyle: {
              color: "#fff",
              fontSize: pxToResponsivePx(12),
            },
            splitLine: {
              //虚线设置
              show: true,
              lineStyle: {
                type: "dashed",
              },
            },
            axisLabel: {
              //x轴文字的配置
              show: true,
              textStyle: {
                color: "#C6C6C6",
                fontSize: pxToResponsivePx(12),
              },
            },
          },
        ],
        series: [
          {
            name: "信息数量",
            type: "bar",
            data: this.harmY,
            smooth: true,
            barWidth: pxToResponsivePx(46), //柱子宽度
            barGap: 0.5, //柱子之间间距
            animationDuration: function (idx) {
              return idx * 500 + 1000;
            },
            areaStyle: {
              normal: {
                color: new this.$echarts.graphic.LinearGradient(
                  0,
                  0,
                  0,
                  1,
                  [
                    {
                      offset: 0,
                      color: "rgba(230,104,78, 0.9)",
                    },
                    {
                      offset: 0.9,
                      color: "rgba(230,104,78, 0)",
                    },
                  ],
                  false
                ),
                shadowColor: "rgba(230,104,78, 0.9)", //阴影颜色
                shadowBlur: 20,
              },
            },
            itemStyle: {
              normal: {
                barBorderRadius: [5, 5, 0, 0],
                color: "#4F62F9",
                opacity: 1,
              },
            },
          },
        ],
      };
      myChart.setOption(option);
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .el-loading-spinner .path {
  stroke: #ed731f;
}

::v-deep .el-icon-arrow-left,
::v-deep .el-icon-arrow-right {
  position: relative;
  top: -2px;
}

::v-deep .el-tabs__nav-wrap:after {
  opacity: 0;
}

::v-deep .el-card__body {
  padding-top: 0px;
}

::v-deep .el-tabs__active-bar {
  background-color: #ed731f;
}

::v-deep .el-tabs__item.is-active {
  color: #ed731f;
}

::v-deep .el-tabs__item:hover {
  color: #ed731f;
}

::v-deep .el-tabs .el-tabs__nav {
  padding-right: 30px;
}

.judgedDataTeamName {
  width: 100%;
  overflow: hidden;
  box-sizing: border-box;
}

.harmful-chart {
  width: 100%;
  height: 205px
}

.box7-height {}
</style>
