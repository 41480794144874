// 异常分析
<template>
  <div>
    <!-- 标题栏 -->
    <TitleCard>
      <template v-slot:title><Title>异常分析</Title></template>
      <template v-slot:com>
        <ButtonSelection
          :buttonList="buttonList"
          @setButtonSelectFn="setButtonSelectFn"
        />
      </template> 
    </TitleCard>
    <!--  内容栏  -->
    <el-card shadow="never" class="box3-height">
      <div class="judgedDataTeamName">
        <el-tabs v-model="teamActiveName" @tab-click="judgedNavChange">
          <el-tab-pane
            v-for="(item, index) in tabLIst"
            :key="index"
            :label="item.label"
            :name="String(item.value)"
          ></el-tab-pane>
        </el-tabs>
      </div>
      <div class="echartsBox">
        <!-- 有害信息echarts -->
        <div
          id="Harecharts"
          style="width: 33%; height: 100%"
          v-loading="harechartsLoading"
          ref="Harecharts"
        ></div>
        <!-- 错误表述echarts -->
        <div
          id="Errecharts"
          style="width: 33%; height: 100%"
          v-loading="errechartsLoading"
          ref="Errecharts"
        ></div>
        <!-- 平台账号echarts -->
        <div
          id="Numecharts"
          style="width: 33%; height: 100%"
          v-loading="numechartsLoading"
          ref="Numecharts"
        ></div>
      </div>
    </el-card>
  </div>
</template>

<script>
import TitleCard from "../../../components/TitleCard";
import ButtonSelection from "../../../components/ButtonSelection";
import Title from "../../../base/Title";
import Index from "@/api/index/index";
import Error from "@/api/error/index";
import { pxToResponsivePx } from "@/utils/pxToResponsivePx";
export default {
  components: {
    TitleCard,
    Title,
    ButtonSelection,
  },
  data() {
    return {
      harechartsLoading: false, //加载效果
      errechartsLoading: false, //加载效果
      numechartsLoading: false, //加载效果
      buttonList: ['当天',"本月", "近三月", "半年度", "本年度"],
      tabLIst: [],
      teamActiveName: "0",
      HarechartsData: [], //有害信息数据 
      ErrechartsData: [], //错误表述数据
      NumechartsData: [], //平台账号数据
      cycleData: 5, //切换时间周期
    };
  }, 
  beforeDestroy(){
    this.$echarts.dispose(this.$refs.Numecharts);
    this.$echarts.dispose(this.$refs.Errecharts);
    this.$echarts.dispose(this.$refs.Harecharts);
  },
  methods: {
    // 平台切换
    judgedNavChange(tab) {
      this.getAbnormalNum(tab._props.name / 1);
      this.getAnomalyAnalysis(tab._props.name / 1);
      this.getAnomalyAnalysisErr(tab._props.name / 1);
    },
    // 设置Echarts图
    setEcharts(botName, divName, echertsData) { 
      if(!document.getElementById(divName))return
        const myChart = this.$echarts.init(document.getElementById(divName));
        let option = {
          title: [
            {
              text: botName,
              left: "50%",
              bottom: "0px",
              textAlign: "center",
              textStyle: {
                color: "#B8BCC3",
                fontSize: pxToResponsivePx(14),
                fontWeight: "normal",
              },
            },
          ],
          // color:[
          //   "#92F5A8",
          // "#FEBC3F",
          // "#FFD88F",
          // "#184CFF",
          // "#6F90FF",
          // "#0098FF",
          // "#77C8FF",
          // "#00D0A3",
          // ],
          tooltip: {
            trigger: "item",
            textStyle: {
              fontSize: pxToResponsivePx(12),
            }
          },
          series: [
            {
              type: "pie",
              radius: "70%",
              data: echertsData,
              label: {
                show: false,
              },
            },
          ],
        };
        myChart.setOption(option);
        window.addEventListener("resize", () => {
        myChart.resize();
    });
    },
    // 获取平台类型
    async getPlatformList() {
      const res = await Error.getPlatformList();
      if (res.data.code == 200) {
        this.tabLIst = res.data.data;
        this.tabLIst.unshift({
          label: "全部 ",
          value: "0",
        });
      }
    },
    // 获取异常分析中的平台账号异常
    async getAbnormalNum(type) {
      this.numechartsLoading = true;
      let data = {
        aggField: "message_types",
        dictField: "message_type",
      };
      this.NumechartsData = [];
      type && type != 0 ? (data.platformType = type) : false;
      const res = await Index.getAbnormalNum(data);
      if (res.data.code == 200) {
        this.NumechartsData = res.data.data;
        // setTimeout(() => {
          this.setEcharts("平台及账号异常状态分析", "Numecharts", res.data.data);
        // },1000)
        this.numechartsLoading = false;
      }
    },
    // 获取异常分析中的错误表述
    async getAnomalyAnalysisErr(type) {
      this.errechartsLoading = true;
      let data = {
        period: this.cycleData,
      };
      this.ErrechartsData = [];
      type && type != 0 ? (data.platformTypes = type) : false;
      const res = await Index.getAnomalyAnalysisErr(data);
      if (res.data.code == 200) {
        if (res.data.data.misre) {
          res.data.data.misre.forEach((x) => {
            let obj = {
              name: "",
              value: "",
            };
            obj.name = x.value;
            obj.value = x.num;
            this.ErrechartsData.push(obj);
          });
          // setTimeout(() => {
            this.setEcharts(
            "错误表述信息分布",
            "Errecharts",
            this.ErrechartsData
          );
          // }, 1000);
          
          this.errechartsLoading = false;
        } else {
          // setTimeout(() => {
            this.setEcharts("错误表述信息分布", "Errecharts", []);
          // }, 1000);
    
          this.errechartsLoading = false;
        }
      }
    },
    // 获取异常分析中的有害信息
    async getAnomalyAnalysis(type) {
      this.harechartsLoading = true;
      let data = {
        modules: [1],
        period: this.cycleData,
      };
      this.HarechartsData = [];
      type && type != 0 ? (data.platformTypes = type + "") : false;
      const res = await Index.getAnomalyAnalysis(data);
      if (res.data.code == 200) {
        if (res.data.data.harmful) {
          res.data.data.harmful.forEach((x) => {
            let obj = {
              name: "",
              value: "",
            };
            obj.name = x.value;
            obj.value = x.num;
            this.HarechartsData.push(obj);
          });
          // setTimeout(() => {
            this.setEcharts(
            "有害信息类型分析",
            "Harecharts",
            this.HarechartsData
          );
          // }, 1000);
  
          this.harechartsLoading = false;
        } else {
          // setTimeout(()=> {
            this.setEcharts("有害信息类型分析", "Harecharts", []);
          // },1000)
          
          this.harechartsLoading = false;
        }
      }
    },
    // 切换时间周期
    setButtonSelectFn(index) {
      this.teamActiveName = "0";
      // 人后端就这么定的  我也没法
      const indexObj = {
        0:5,
        1:0,
        2:1,
        3:2,
        4:3
      }
      this.cycleData = indexObj[index];
      this.getAnomalyAnalysisErr();
      this.getAnomalyAnalysis();
      this.getAbnormalNum();
    },
  },
   mounted() {
    // this.$nextTick(() => {
      this.getAnomalyAnalysisErr();
      this.getAnomalyAnalysis();
      this.getAbnormalNum();
    // })
  },
  created() {
    localStorage.getItem("platform_type")
      ? ((this.tabLIst = JSON.parse(localStorage.getItem("platform_type"))),
        this.tabLIst.unshift({
          label: "全部 ",
          value: "0",
        }))
      : this.getPlatformList();
  },
};
</script>

<style lang="scss" scoped>
::v-deep .el-loading-spinner .path {
  stroke: #ed731f;
}
.echartsBox {
  width: 100%;
  height: 230px;
  display: flex;
  justify-content: space-between;
}
::v-deep .el-icon-arrow-left,
::v-deep .el-icon-arrow-right {
  position: relative;
  top: -2px;
}
::v-deep .el-tabs__nav-wrap:after {
  opacity: 0;
}
::v-deep .el-card__body {
  padding-top: 0px;
}
::v-deep .el-tabs__active-bar {
  background-color: #ed731f;
}
::v-deep .el-tabs__item.is-active {
  color: #ed731f;
}
::v-deep .el-tabs__item:hover {
  color: #ed731f;
}
::v-deep .el-tabs .el-tabs__nav {
  padding-right: 30px;
}
.judgedDataTeamName {
  width: 100%;
  overflow: hidden;
  box-sizing: border-box;
}
.box3-height {
  height: 306px;
}
</style>
