// 预警信息
<template>
  <div>
    <!-- 标题栏 -->
    <TitleCard>
      <template v-slot:title>
        <Title>预警信息</Title>
      </template>
      <template v-slot:com>
        <ButtonSelection :buttonList="buttonList" @setButtonSelectFn="setButtonSelectFn" />
      </template>
    </TitleCard>
    <!--  内容栏  -->
    <el-card shadow="never" v-loading="loading" class="box2-height">
      <div class="news-box" v-show="newsdata.length > 0">
        <NewsList v-for="(item, index) in newsdata" :key="index" :news="item" />
      </div>
      <div class="analyse-container" v-show="newsdata.length == 0 || !newsdata">
        <div class="analyse-container-img">
          <img src="@/assets/img/gjh/noyj.png" />
          <p>暂无数据</p>
        </div>
      </div>
    </el-card>
  </div>
</template>

<script>
import TitleCard from "../../../components/TitleCard";
import ButtonSelection from "../../../components/ButtonSelection";
import Title from "../../../base/Title";
import NewsList from "../../../base/NewsList";
import Index from "@/api/index/index";
export default {
  components: {
    TitleCard,
    Title,
    ButtonSelection,
    NewsList,
  },
  data() {
    return {
      buttonList: ["错误表述", "异常状态", "有害信息", "监测方案"],
      newsdata: [], //列表数据
      loading: false, //加载效果
    };
  },
  created() {
    // 给陕西省委打包时候用的
    if (JSON.parse(window.localStorage.getItem("isWangxinban"))) {
      this.buttonList = [];
      this.getWarningInformation(4);
      return;
    }
    // 非陕西省委打包用
    this.getWarningInformation(1);
  },
  methods: {
    // 获取预警信息列表
    async getWarningInformation(type) {
      let obj = {
        //接口
        1: "getErrorExpressionList",
        2: "queryPage",
        3: "getlist",
        4: "planMonitorDataList",
      };
      let data = {
        //参数
        1: { page: 1, limit: 5 },
        2: { pageNo: 1, pageSize: 5 },
        3: { page: 1, limit: 5 },
        4: { page: 1, limit: 5 },
      };
      let result = {
        //数据
        1: "title",
        2: "accountName",
        3: "title",
        4: "title",
      };
      this.loading = true;
      try {
        // const res = await Index.getWarningInformation({warnningType: type,});
        const res = await Index[obj[type]](data[type]);
        if (res.data.code == 200) {
          // console.log(res, '?????');
          this.newsdata = res.data.data.list.map((item) => {
            (item.title = item[result[type]]),
              (item.createTime = item.createTime),
              (item.warningType = Number(type));
            return item;
          });
          this.loading = false;
        }
      } catch (error) {
        this.loading = false;
      }
    },
    // 切换平台预警
    setButtonSelectFn(index) {
      this.getWarningInformation(index + 1);
    },
  },
};
</script>

<style lang="scss" scoped>
/* 暂无数据用的 */
.analyse-container {
  height: 270px;
}

.analyse-container-img {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.analyse-container-img p {
  font-size: 14px;
  color: #999999;
}

.analyse-container-img img {
    width: 400px;
}

::v-deep .el-card__body {
  padding: 10px;
}

::v-deep .el-loading-spinner .path {
  stroke: #ed731f;
}

// .news-box {
//   height: 240px;
// }

.box2-height {
  height: 290px;
}
</style>
