// 平台及账号发布信息数量情况
<template>
  <div>
    <!-- 标题栏 -->
    <TitleCard>
      <template v-slot:title>
        <Title>平台及账号发布信息数量情况</Title>
      </template>
      <template v-slot:com>
        <ButtonSelection :buttonList="buttonList" @setButtonSelectFn="setButtonSelectFn" />
      </template>
    </TitleCard>
    <!-- 内容 -->
    <!--  内容栏  -->
    <el-card shadow="never" class="box4-height" v-loading="specialActionsLoading">
      <div id="Basic" class="echarts-box">我是echarts图</div>
    </el-card>
  </div>
</template>

<script>
import TitleCard from "../../../components/TitleCard";
import ButtonSelection from "../../../components/ButtonSelection";
import Index from "@/api/index/index";
import Title from "../../../base/Title";
import { pxToResponsivePx } from "@/utils/pxToResponsivePx";
export default {
  components: {
    TitleCard,
    Title,
    ButtonSelection,
  },
  data() {
    return {
      specialActionsLoading: false, //加载效果
      buttonList: ["今天", "近7天", "近30天"],
      echartsX: [], //echartsX轴数据
      echartsY: [], //echartsY轴数据
    };
  },
  mounted() {
    this.setEcharts();
  },
  created() {
    this.getPlatformAccountRelease(0);
  },
  methods: {
    // 获取平台及账号发布信息数量情况
    async getPlatformAccountRelease(index) {
      this.specialActionsLoading = true;
      const data = { period: index };
      this.echartsX = [];
      this.echartsY = [];

      try {
        const res = await Index.getPlatformAccountRelease(data);
        if (res?.data?.code === 200) {
          if (res.data.data) {
            res.data.data.forEach(item => {
              this.echartsX.push(item.value);
              this.echartsY.push(item.num);
            });
          }
          // 无论 res.data.data 是否存在，都需要停止加载状态并更新 Echarts
          this.specialActionsLoading = false;
          this.setEcharts();
        }
      } catch (error) {
        console.error('获取平台及账号发布信息数量情况失败:', error);
        this.specialActionsLoading = false;
      } finally {
        this.specialActionsLoading = false;
      }
    },

    // async getPlatformAccountRelease(index) {
    //   this.specialActionsLoading = true;
    //   let data = {
    //     period: index,
    //   };
    //   this.echartsX = [];
    //   this.echartsY = [];
    //   const res = await Index.getPlatformAccountRelease(data);
    //   if (res.data.code == 200) {
    //     if (res.data.data) {
    //       res.data.data.forEach((x) => {
    //         this.echartsX.push(x.value);
    //         this.echartsY.push(x.num);
    //       });
    //       this.specialActionsLoading = false;
    //       this.setEcharts();
    //     } else {
    //       this.specialActionsLoading = false;
    //       this.setEcharts();
    //     }
    //   }

    // },
    setButtonSelectFn(index) {
      this.getPlatformAccountRelease(index);
    },
    setEcharts() {
      if (!document.getElementById("Basic")) return
      const myChart = this.$echarts.init(document.getElementById("Basic"));
      let option = {
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "shadow",
          },
          textStyle: {
            fontSize: pxToResponsivePx(12),
          }
        },
        grid: {
          left: "3%",
          top: "16%",
          right: "3%",
          bottom: "10%",
          containLabel: true,
        },
        xAxis: [
          {
            type: "category",
            data: this.echartsX,
            axisTick: {
              show: false,
            },
            axisLabel: {
              interval: 0,
              rotate: -40, //值>0向右倾斜，值<0则向左倾斜
              textStyle: {
                color: "#C6C6C6",
                fontSize: pxToResponsivePx(12),
              },
            },
            axisLine: {
              show: true,
              lineStyle: {
                color: "#D8D8D8",
              },
            },
          },
        ],
        yAxis: [
          {
            type: "value",
            axisTick: {
              show: false,
            },
            nameTextStyle: {
              color: "#fff",
              fontSize: 11,
            },
            splitLine: {
              show: true,
              lineStyle: {
                type: "dashed",
              },
            },
            axisLabel: {
              show: true,
              textStyle: {
                color: "#C6C6C6",
                fontSize: pxToResponsivePx(12),
              },
            },
          },
        ],
        series: [
          {
            name: "发布信息数量",
            type: "bar",
            data: this.echartsY,
            smooth: true,
            barWidth: pxToResponsivePx(10), //柱子宽度
            barGap: 0.5, //柱子之间间距
            animationDuration: function (idx) {
              return idx * 500 + 1000;
            },
            areaStyle: {
              normal: {
                color: new this.$echarts.graphic.LinearGradient(
                  0,
                  0,
                  0,
                  1,
                  [
                    {
                      offset: 0,
                      color: "rgba(230,104,78, 0.9)",
                    },
                    {
                      offset: 0.9,
                      color: "rgba(230,104,78, 0)",
                    },
                  ],
                  false
                ),
                shadowColor: "rgba(230,104,78, 0.9)",
                shadowBlur: 20,
              },
            },
            itemStyle: {
              normal: {
                barBorderRadius: [5, 5, 0, 0],
                color: "#FF954D",
                opacity: 1,
              },
            },
          },
        ],
      };
      myChart.setOption(option);
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .el-loading-spinner .path {
  stroke: #ed731f;
}

.box4-height {
  height: 306px;
}

.echarts-box {
  height: 270px;
}
</style>